<template>
  <div v-if="text==true">
    Smartphone/Iphone<br />
	•	Iphone X<br />
	•	Iphone XR<br />
	•	Iphone XS & XS MAX<br />
	•	Iphone 11<br />
	•	Iphone 11 PRO & PRO MAX<br />
	•	Iphone 12<br />
	•	Iphone 12 MINI<br />
	•	Iphone 12 PRO & PRO MAX<br />
	•	Iphone 13<br />
	•	Iphone 13 MINI<br />
	•	Iphone 13 PRO & PRO MAX<br />
	•	Iphone 14<br />
	•	Iphone 14 PLUS<br />
	•	Iphone 14 PRO & PRO MAX<br /><br />
	
Tablette/Ipad<br />
	•	Ipad PRO 11 Inch (1st generation) <br />
	•	Ipad PRO 11 Inch (2nd generation) <br />
	•	Ipad PRO 11 Inch (3rd generation) <br />
	•	Ipad PRO 12.9 Inch (3rd generation) <br />
	•	Ipad PRO 12.9 Inch (4th generation) <br />
	•	Ipad PRO 12.9 Inch (5th generation) <br />
  </div>
  <div v-else class="list">
      <div class="row">
          <div class="device">iPhone X</div>
          <div class="device">iPhone XR</div>
          <div class="device">iPhone <span class="subtitle">XS & XS MAX</span></div>
          <div class="device">iPhone 11</div>
          <div class="device">iPhone 11 <span class="subtitle">PRO & PRO MAX</span></div>
      </div>
      <div class="row">
          
          <div class="device">iPhone 12</div>
          <div class="device">iPhone 12 <span class="subtitle">MINI</span></div>
          <div class="device">iPhone 12 <span class="subtitle">PRO & PRO MAX</span></div>
          <div class="device">iPhone 13</div>
          <div class="device">iPhone 13 <span class="subtitle">MINI</span></div>
      </div>
      <div class="row">
          
          
      </div>
      <div class="row">
          
          <div class="device">iPhone 13 <span class="subtitle">PRO & PRO MAX</span></div>
          <div class="device">iPhone 14</div>
          <div class="device">iPhone 14 <span class="subtitle">PLUS</span></div>
          <div class="device">iPhone 14 <span class="subtitle">PRO & PRO MAX</span></div>
          
      </div>
      <div class="row">
          <div class="device">iPad Pro<span class="subtitle">11 pouces (1e)</span></div>
          <div class="device">iPad Pro<span class="subtitle">11 pouces (2e)</span></div>
          <div class="device">iPad Pro<span class="subtitle">11 pouces (3e)</span></div>
          <div class="device">iPad Pro<span class="subtitle">12,9 pouces (3e)</span></div>
          </div>
      <div class="row">
          <div class="device">iPad Pro<span class="subtitle">12,9 pouces (4e)</span></div>
          <div class="device">iPad Pro<span class="subtitle">12,9 pouces (5e)</span></div>
          
      </div>
  </div>
</template>

<script>

export default {
  name: 'DeviceList',
  
  components: {
 
  },
  props:['text'],
  data() {
    return {
     
    }
  },
  watch: { 
    
  },
  methods:{
    
  },
  
  
}
</script>
<style scoped>


 .list
{
	
	margin-top:20px;
}

 .list .row
{
	margin-top:20px;
}
.device .subtitle
{
	font-size:9px;
	display:block;
	width:100%;
	text-align:center;
}
.device
{
	display:inline-block;
	margin-right:10px;
	border-radius: 17px;
	font-size:12px;
	text-align:center;
	padding:10px 10px;
	width:95px;
	backdrop-filter: blur(30px);
-webkit-backdrop-filter: blur(30px);
background:rgba(0,0,0,0.1);
}
 
@media screen and (max-width:760px)
{

  body  .device-list .device
	{
		padding: 10px 3px;
    width: 89px;
    margin-right: 3px;
    margin-top: 3px;
	}
	body  .device-list
	{
		min-width: 80px;
	}

}
</style>