<template>
  <div class="template-full-width">
    
      <div class="element-top">
        <div class="left">
          <div>
            <div class="title" v-if="language=='fr'">

              <span class="line1" >L'AJUSTEMENT<br />PARFAIT</span>
              <!-- <br /><span class="line3">PERFECT FIT</span> -->
            </div>
            <div class="title" v-else>

              <span class="line1" >THE PERFECT FIT EXPERIENCE</span>
              <!-- <br /><span class="line3">PERFECT FIT</span> -->
            </div>
            <br />
            <span v-if="language=='fr'" class="subtitle">Des Lunettes Conçues<br />Pour Votre Visage.</span>
            <span v-else class="subtitle">Eyewear Crafted For Your<br />Unique Face Contours.</span>
            <br />
            <br /><br /><br /><br />
            <div v-if="language=='fr'" class="see" @click="toBottom(1)">Voir comment ça marche</div>
            <div v-else class="see" @click="toBottom(1)">See how it works</div>
            <br />
            <img src="../../public/assets/images/arrow-bottom.png" @click="toBottom(1)" class="arrow-bottom" />
            
          </div>
        </div>
        <div class="right"></div>
      </div>

      <div class="element-choice" id="top1">
        <div class="title" v-if="language=='fr'">
          NOTRE TECHNOLOGIE DE FITTING PAR IA NE SE CONTENTE PAS DE MESURER. <span class="black">ELLE COMPREND</span> .
        </div>
        <div class="title" v-else>
          OUR AI-DRIVEN FITTING TECHNOLOGY DOESN’T JUST MEASURE. <span class="black">IT UNDERSTANDS</span> .
        </div>
        <div class="text" v-if="language=='fr'">
          Notre scan facial interprète avec précision les courbes et les contours de votre visage, garantissant que vos lunettes épousent parfaitement vos traits. Une harmonie parfaite en confort et style, sans le moindre compromis.
        </div>
        <div class="text" v-else>
          Our face scan interprets the curves and contours of your face with precision, ensuring your glasses fit not just well, but perfectly. Its comfort and style, seamlessly blended.
        </div>
        <div v-if="language=='fr'" class="see" @click="toBottom(2)">Découvrir comment ça fonctionne</div>
        <div v-else class="see" @click="toBottom(2)">See how it works</div>
        <br />
        <img src="../../public/assets/images/arrow-bottom.png" @click="toBottom(2)" class="arrow-bottom" />
        <br /><br /><br /><br />
        <div class="filter-content">
          <div class="bg-filter" :class="{right:currentFilter=='onesize'}"></div>
          <div v-if="language=='fr'"  class="filter" :class="{active:currentFilter=='measure'}" @click="currentFilter='measure'">Sur-mesure</div>
          <div v-else class="filter" :class="{active:currentFilter=='measure'}" @click="currentFilter='measure'">Custom-fit</div>
          <div v-if="language=='fr'" class="filter" :class="{active:currentFilter=='onesize'}" @click="currentFilter='onesize'">Taille unique</div>
          <div v-else class="filter" :class="{active:currentFilter=='onesize'}" @click="currentFilter='onesize'">One size</div>
        </div>
      </div>
      <div id="top2">
        <template v-if="currentFilter=='measure'" >
          <div class="element-step" :class="{active:elementActive==1}" id="item1">
            <div class="left">
              <div class="top">
                <img src="../../public/assets/images/arrow2.png" v-if="elementActive==1" class="arrow-title" />
                <img src="../../public/assets/images/arrow5.png" v-else class="arrow-title" />
                  
                STEP 01</div>

              <div class="content">
                <div class="title" v-if="language=='fr'">Créez Votre Style</div>
                <div class="title" v-else>Design Your Look</div>
                
                <div class="text" v-if="language=='fr'">Sur le site Visages, choisissez un modèle et exprimez votre créativité grâce à notre gamme d'options de personnalisation, incluant <span class="underline">couleurs</span>, <span class="underline">textures</span>, <span class="underline">branches</span>, <span class="underline">verres</span> et <span class="underline">gravure</span>.</div>
                <div class="text" v-else>Through the Visages website, select a base model and unleash your creativity with our array of customisation options, including <span class="underline">colors</span>, <span class="underline">textures</span>, <span class="underline">temples</span>, <span class="underline">lenses</span> and <span class="underline">engraving</span>.</div>
              </div>
            </div>
            <div class="right">
              <div class="img"><video muted playsinline loop autoplay>
                
                <source src="https://player.vimeo.com/progressive_redirect/playback/950773449/rendition/540p/file.mp4?loc=external&log_user=0&signature=f48986b41010eb7844e17f142fad82d476dd2e45ec55a50a1fdaa1494dae6e34"
                                type="video/mp4">

                        Sorry, your browser doesn't support embedded videos.
              </video></div>
              <!-- <div class="bottom">
                <div class="left-bottom"><img src="../../public/assets/images/arrow5.png" class="arrow-white" /> <span class="text">FRAME SELECTION</span></div>
                <div class="right-bottom">+</div>
              </div> -->
            </div>
          </div>

          <div class="element-step" :class="{active:elementActive==2}" id="item2">
            <div class="left">
              <div class="top">
                <img src="../../public/assets/images/arrow2.png" v-if="elementActive==2" class="arrow-title" />
                <img src="../../public/assets/images/arrow5.png" v-else class="arrow-title" />
                STEP 02</div>

              <div class="content">
                <div class="title" v-if="language=='fr'">Choisissez « Sur-Mesure » & Finalisez la Commande</div>
                <div class="title" v-else>Select « Made To Fit » & Purchase</div>
                <div class="note" v-if="language=='fr'">Note : Disponible Uniquement sur Certains Appareils</div>
                <div class="note" v-else>Note: Only Available on Certain Devices</div>
                <div class="text" v-if="language=='fr'">Finalisez votre commande en ligne, et vous recevrez un email de confirmation contenant des instructions simples sur la marche à suivre, y compris un lien vers notre application pour le scan du visage.</div>
                <div class="text" v-else>Complete your order online, and you will receive a confirmation email with simple instructions on how to proceed, including a link to our App for the face scan.</div>
              </div>
            </div>
            <div class="right">
              <div class="img">
                <video muted playsinline loop autoplay>
                
                <source src="https://player.vimeo.com/progressive_redirect/playback/950775202/rendition/540p/file.mp4?loc=external&log_user=0&signature=d1990f7c199d52d0e7a3621296bba015f579b3a8249bbc4653577670eec992fb"
                                type="video/mp4">

                        Sorry, your browser doesn't support embedded videos.
              </video>

              </div>
              <!-- <div class="bottom">
                <div class="left-bottom"><img src="../../public/assets/images/arrow5.png" class="arrow-white" /> <span class="text">FRAME SELECTION</span></div>
                <div class="right-bottom">+</div>
              </div> -->
            </div>
          </div>

          <div class="element-step" :class="{active:elementActive==3}" id="item3">
            <div class="left">
              <div class="top">
                <img src="../../public/assets/images/arrow2.png" v-if="elementActive==3" class="arrow-title" />
                <img src="../../public/assets/images/arrow5.png" v-else class="arrow-title" />
                STEP 03</div>

              <div class="content">
                <div class="title" v-if="language=='fr'">Téléchargement de l'App & Processus de Scan</div>
                <div class="title" v-else>App download & Scanning process</div>
                <!-- <div class="note">Download the App and follow the on-screen guide to capture the precise measurements of your face.</div> -->
                <div class="text" v-if="language=='fr'">Munissez-vous de votre <b>adresse email</b> ainsi que de votre <b>numéro de commande</b>, téléchargez l'application VISAGES sur l'Apple Store et suivez les instructions pour capturer les mesures précises de votre visage.</div>
                <div class="text" v-else>Have your email address and order number ready, download the VISAGES mobile application from the Apple Store, and follow the instructions to capture precise measurements of your face.</div>
              </div>
            </div>
            <div class="right">
              <div class="img">

                <video muted playsinline loop autoplay>
                
                <source src="https://player.vimeo.com/progressive_redirect/playback/950775504/rendition/540p/file.mp4?loc=external&log_user=0&signature=76e02757f49d1c81dfb0a7622e5729ecd71297b3c4d679f88fb7cb4e659b3503"
                                type="video/mp4">

                        Sorry, your browser doesn't support embedded videos.
              </video>
              
              </div>
              <!-- <div class="bottom">
                <div class="left-bottom"><img src="../../public/assets/images/arrow5.png" class="arrow-white" /> <span class="text">FRAME SELECTION</span></div>
                <div class="right-bottom">+</div>
              </div> -->
            </div>
          </div>

          <div class="element-step" :class="{active:elementActive==4}" id="item4">
            <div class="left">
              <div class="top">
                <img src="../../public/assets/images/arrow2.png" v-if="elementActive==4" class="arrow-title" />
                <img src="../../public/assets/images/arrow5.png" v-else class="arrow-title" />
                STEP 04</div>

              <div class="content">
                <div class="title" v-if="language=='fr'">Production & Livraison</div>
                <div class="title" v-else>Production & Delivery</div>
            
                <div class="text" v-if="language=='fr'">Nous fabriquons vos lunettes sur commande, tout en assurant une production sans gaspillage. Fabriquées avec soin en Europe, elles sont envoyées dans les plus brefs délais pour être livrées chez vous.Personnalisées et écologiques – exclusivement les vôtres. </div>
                <div class="text" v-else>We manufacture your glasses on-demand, just for you, with a zero waste guarantee. Crafted with precision in Europe, they’re dispatched promptly, ensuring a swift journey from our hands to your doorstep. Uniquely tailored & eco-friendly – exclusively yours.</div>
              </div>
            </div>
            <div class="right">
              <div class="img">

                <video muted playsinline loop autoplay>
                
                <source src="https://player.vimeo.com/progressive_redirect/playback/950775630/rendition/540p/file.mp4?loc=external&log_user=0&signature=54d5da097fb22fc4a4a4037cf182ed39e28acffb99925a31705573bd60ebb844"
                                type="video/mp4">

                        Sorry, your browser doesn't support embedded videos.
              </video>
              </div>
              <!-- <div class="bottom">
                <div class="left-bottom"><img src="../../public/assets/images/arrow5.png" class="arrow-white" /> <span class="text">FRAME SELECTION</span></div>
                <div class="right-bottom">+</div>
              </div> -->
            </div>
          </div>
        </template>
      
        <template v-else >
          <div class="element-step" :class="{active:elementActive==1}" id="item1-size">
            <div class="left">
              <div class="top">
                <img src="../../public/assets/images/arrow2.png" v-if="elementActive==1" class="arrow-title" />
                <img src="../../public/assets/images/arrow5.png" v-else class="arrow-title" />
                STEP 01</div>

              <div class="content">
                <div class="title" v-if="language=='fr'">Créez Votre Style</div>
                <div class="title" v-else>Design Your Look</div>
                <div class="text" v-if="language=='fr'">Sur le site Visages, choisissez un modèle et exprimez votre créativité grâce à notre gamme d'options de personnalisation, incluant <span class="underline">couleurs</span>, <span class="underline">textures</span>, <span class="underline">branches</span>, <span class="underline">verres</span> et <span class="underline">gravure</span>.</div>
                <div class="text" v-else>Through the Visages website, select a base model and unleash your creativity with our array of customisation options, including  <span class="underline">colors</span>, <span class="underline">textures</span>, <span class="underline">temples</span>, <span class="underline">lenses</span> and <span class="underline">engraving</span>.</div>
              </div>
            </div>
            <div class="right">
              <div class="img">

                <video muted playsinline loop autoplay>
                
                <source src="https://player.vimeo.com/progressive_redirect/playback/950773449/rendition/540p/file.mp4?loc=external&log_user=0&signature=f48986b41010eb7844e17f142fad82d476dd2e45ec55a50a1fdaa1494dae6e34"
                                type="video/mp4">

                        Sorry, your browser doesn't support embedded videos.
              </video>
              </div>
              <!-- <div class="bottom">
                <div class="left-bottom"><img src="../../public/assets/images/arrow5.png" class="arrow-white" /> <span class="text">FRAME SELECTION</span></div>
                <div class="right-bottom">+</div>
              </div> -->
            </div>
          </div>

          <div class="element-step" :class="{active:elementActive==2}" id="item2-size">
            <div class="left">
              <div class="top">
                <img src="../../public/assets/images/arrow2.png" v-if="elementActive==2" class="arrow-title" />
                <img src="../../public/assets/images/arrow5.png" v-else class="arrow-title" />
                STEP 02</div>

              <div class="content">
                <div class="title" v-if="language=='fr'">Choisissez “Taille Unique” & Finalisez la Commande</div>
                <div class="title" v-else>Select “One Size” & Purchase</div>
              
                <div class="text" v-if="language=='fr'">Vérifiez votre création, ajoutez-la au panier et passez au paiement. Vous recevrez un email confirmant votre commande.</div>
                <div class="text" v-else>Check your design, add it to the cart, and proceed to payment. You’ll get an email confirming your unique order. It’s that simple.</div>
              </div>
            </div>
            <div class="right">
              <div class="img">

                <video muted playsinline loop autoplay>
                
                <source src="https://player.vimeo.com/progressive_redirect/playback/950775389/rendition/540p/file.mp4?loc=external&log_user=0&signature=d4e6448cba40be1cc88e7bd473ace7f370ec596cc58c8b2b6666bfff2193491c"
                                type="video/mp4">

                        Sorry, your browser doesn't support embedded videos.
              </video>

              </div>
              <!-- <div class="bottom">
                <div class="left-bottom"><img src="../../public/assets/images/arrow5.png" class="arrow-white" /> <span class="text">FRAME SELECTION</span></div>
                <div class="right-bottom">+</div>
              </div> -->
            </div>
          </div>

        

          <div class="element-step" :class="{active:elementActive==3}" id="item3-size">
            <div class="left">
              <div class="top">
                <img src="../../public/assets/images/arrow2.png" v-if="elementActive==3" class="arrow-title" />
                <img src="../../public/assets/images/arrow5.png" v-else class="arrow-title" />
                STEP 03</div>

              <div class="content">
                <div class="title" v-if="language=='fr'">Production & Livraison</div>
                <div class="title" v-else>Production & Delivery</div>
            
                <div class="text" v-if="language=='fr'">Nous fabriquons vos lunettes sur commande, tout en assurant une production sans gaspillage. Fabriquées avec soin en Europe, elles sont envoyées dans les plus brefs délais pour être livrées chez vous.Personnalisées et écologiques – exclusivement les vôtres. </div>
                <div class="text" v-else>We manufacture your glasses on-demand, just for you, with a zero waste guarantee. Crafted with precision in Europe, they’re dispatched promptly, ensuring a swift journey from our hands to your doorstep. Uniquely tailored & eco-friendly – exclusively yours.</div>
              </div>
            </div>
            <div class="right">
              <div class="img">
                <video muted playsinline loop autoplay>
                
                <source src="https://player.vimeo.com/progressive_redirect/playback/950775630/rendition/540p/file.mp4?loc=external&log_user=0&signature=54d5da097fb22fc4a4a4037cf182ed39e28acffb99925a31705573bd60ebb844"
                                type="video/mp4">

                        Sorry, your browser doesn't support embedded videos.
              </video>

              </div>
              <!-- <div class="bottom">
                <div class="left-bottom"><img src="../../public/assets/images/arrow5.png" class="arrow-white" /> <span class="text">FRAME SELECTION</span></div>
                <div class="right-bottom">+</div>
              </div> -->
            </div>
          </div>
        </template>
      </div>
      <br /><br /><br /><br />
      <Footer :language="language" :displayModalMenu="displayModalMenu" />
    </div>
    
  
</template>

<script>

import Footer from '../components/layout/Footer.vue'
import jQuery  from 'jquery';

import DeviceList from '../components/elements/DeviceList.vue';
import { useMeta } from 'vue-meta'

export default {
  name: 'HowDoesItWorks',

  components: {
    Footer,
    DeviceList,
    
  },
  data() {
    return {
      
      displayDevices:false,
      currentFilter:'measure',
      elementActive:1
    }
    },
    props:["displayModalMenu","language"],
    methods:{
      toBottom(index)
      {
        jQuery('html, body').animate({
          scrollTop: jQuery("#top"+index).offset().top
        }, 500); 
      },
      
     checkScroll()
     {
      
      let nbelements, indexitem1, indexitem2, indexitem3, indexitem4, elem1, elem2, elem3, elem4


      if(this.currentFilter=='measure')
      {
        nbelements=4
        elem1="item1"
        elem2="item2"
        elem3="item3"
        elem4="item4"

      }
      else {
        nbelements=3
        elem1="item1-size"
        elem2="item2-size"
        elem3="item3-size"
      }
      indexitem1=jQuery('#'+elem1).offset().top-200
      indexitem2=jQuery('#'+elem2).offset().top-200
      indexitem3=jQuery('#'+elem3).offset().top-200
      if(nbelements>3)
      {
        indexitem4=jQuery('#'+elem4).offset().top-200
        if(window.scrollY>=indexitem1 && window.scrollY<indexitem2)
        {
          this.elementActive=1
        }
        else if(window.scrollY>=indexitem2 && window.scrollY<indexitem3)
        {
          this.elementActive=2
        }
        else if(window.scrollY>=indexitem3 && window.scrollY<indexitem4)
        {
          this.elementActive=3
        }
        else if(window.scrollY>=indexitem4)
        {
          this.elementActive=4
        }
      }
      else {
        if(window.scrollY>=indexitem1 && window.scrollY<indexitem2)
        {
          this.elementActive=1
        
        }
        else if(window.scrollY>=indexitem2 && window.scrollY<indexitem3)
        {
          this.elementActive=2
          
        }
        else if(window.scrollY>=indexitem3)
        {
          this.elementActive=3
          
        }
      }
      
    }
  },
  created() {
    if(this.language=='fr')
        {
          useMeta({
    title: "VISAGES - Comment ça marche",
    
    htmlAttrs: { lang: this.language, amp: true },
    
})



   
    document.querySelector('meta[name="description"]').setAttribute('content',"Notre scan facial interprète avec précision les courbes et les contours de votre visage, garantissant que vos lunettes épousent parfaitement vos traits. Une harmonie parfaite en confort et style, sans le moindre compromis.")
        }
        else
        {


          useMeta({
    title: "VISAGES - How it works",
    
    htmlAttrs: { lang: this.language, amp: true },
    
})

   
    document.querySelector('meta[name="description"]').setAttribute('content',"Our face scan interprets the curves and contours of your face with precision, ensuring your glasses fit not just well, but perfectly. Its comfort and style, seamlessly blended.")
      
        }
  },

  mounted()
  {
    
     
								
      jQuery(window).scroll(this.checkScroll);
        
   
    
   
  }
  
}
</script>
<style scoped>
@media screen and (min-width:760px) and (max-width:1025px)
{
  .element-top .left .title

  {
    font-size:70px !important;
  }
  .subtitle
  {
    font-size:22px !important;
  }
  .element-top .left
  {
    justify-content: center;
    width: 100% !important;
  }
  .element-step .right 
  {
    margin-top:30px;
    width:100% !important;
  }
  .element-step .left 
  {
    min-height:100px !important;
    width:100% !important;
  }
  .element-step {
    min-height:100px !important;
    flex-direction: column;
  }
  .element-top .right 
  {
    margin-top: 50px;
    width: 100% !important;
    min-height: 60vh !important;
  }
  
  .element-top
  {
    flex-direction: column;
  }
}
@media screen and (min-width:1024px) and (max-width:1380px)
{
  .element-top .left .title
  {
    font-size:80px !important;
  }
}
@media screen and (max-width:760px)
{
  .element-top .left .title .line3
  {
    left:30px !important;
  }
  .element-top .left .title .line1
  {
    left:20px !important;
  }
  .element-top .left .title .line2
  {
    font-size: 24px !important;
  }
  .element-top .left .title
  {
    font-size:52px !important;
  }
  .left-bottom .text
  {
    top:-10px !important;
  }
  .element-step .left .content .title {
    padding-top:14px !important;
    border-top: 0 !important;
  }
  .element-step .right .bottom .right-bottom
  {
    line-height: 1.5 !important;
  }
  .arrow-title {
    width:48px !important;
    top:10px !important;
  }
  .arrow-white 
  {
    width:38px !important;
  }
  .element-step .right .bottom {
    font-size:14px !important;
  }
  .element-step .left .content .text
  {
    font-size: 18px !important;
  }
  .element-step .left .top
  {
    font-size: 45px !important;
  }
  .element-step .left {
    width:100% !important;
    min-height: 5px !important;
  }
  .element-step .right {
   margin-top:30px;
    width:100% !important;
  }
  .element-step {
    flex-direction: column;
    margin-top:50px !important;
  }
  .filter-content
  {
    width:200px !important;
  }
  .filter {
    font-size:13px !important;
  }
  .element-choice .text
  {
    font-size:18px !important;
  }
  .element-choice
  {
    margin-top:50px !important;
  }
  .see {
    font-size:16px !important;
  }
  .arrow-bottom {
    width:27px !important;
  }
  .element-top .arrow-bottom {
    margin-left:0;
    position: relative;
    top:2px;
    display: inline-block !important;
  }
  .subtitle
  {
    font-size: 21px !important;
  }
  .element-top .right {
    width:100% !important;
    min-height: 60vh !important;
  }
  .element-top .left {
    justify-content: center;
    width:100% !important;
    padding-bottom:40px;
  }
  .element-top
  {
    flex-direction:column;
  }
}
.left-bottom .text {
  position: relative;
  top:-15px;
}
.arrow-white {
  filter: brightness(0) invert(1);
  display: inline-block;
  width:55px;
  margin-right:30px;
  
  position: relative;
  top:5px;
}
.arrow-title {
  display: inline-block;
  width:55px;
  margin-right:30px;
  
  position: relative;
  top:8px;
}
.element-choice .arrow-bottom{
display:inline-block;
clear:both;
}
.arrow-bottom {
  width:42px;
  cursor:pointer;
  margin-top:20px;
}
.element-step .right .bottom .right-bottom {
  font-size:25px;
  line-height: 2.4;
}
.element-step.active .top {
  font-weight: 600;
}
.element-step .right .bottom {
  display: flex;
  position: absolute;
  left:0;
  justify-content: space-between;
  bottom:0;
  width:100%;
  color:#fff;
  font-size:28px;
  z-index: 2;
  border-top:1px solid #fff;
  padding:10px 20px 15px 20px;
}
.element-step .right .img video
{
  position:relative;
  left:0;
  margin-left:auto;
  margin-right:auto;
  right:0;
  z-index:0;
  width:80%;
  height:auto;
}

.element-step .right .img {
 
  position:relative;
  left:0;
  right:0;
  width:100%;
  height:100%;
  background-size: cover;
  background-position: center center;
}
.element-step .left .content .title {
  font-size:42px;
  font-weight: 600;
  line-height: 0.8;
  border-top:1px solid #ddd;
  border-bottom:1px solid #ddd;
  padding-top:10px;
  padding-bottom:20px;
} 
.element-step .left .content .text {
  font-size:22px;


  border-bottom:1px solid #ddd;
  padding-top:30px;
  padding-bottom:30px;
} 
.element-step .left .content .note {
  font-size:22px;
  font-weight: 600;

  border-bottom:1px solid #ddd;
  padding-top:10px;
  padding-bottom:15px;
} 
.element-step .left .top {
  font-size:70px;
 
  border-top:1px solid #ddd;
  border-bottom:1px solid #ddd;
  padding-top:10px;
  padding-bottom:20px;
 
}
.element-step .left {
  width:45%;
  display: flex;
  padding-right:5%;
  min-height:75vh;
  flex-direction: column;
  height:100%;
  justify-content: space-between;
}
.element-step .right {
  width:55%;
  height:100%;
 
  position:relative;
 
}
.underline{
  text-decoration: underline;
}
.element-step {
  margin-top:150px;
  display:flex;
  padding-left:5%;
  padding-right:5%;
  padding-top:5%;
  padding-bottom:5%;
  min-height:95vh;
  height:100%;
}
.filter-content{
  background: rgba(118, 118, 128, 0.12) 0% 0% no-repeat padding-box;
border-radius: 8px;
display: flex;
width:400px;
align-self: center;
position:relative;
margin-left: auto;
margin-right:auto;

}
.bg-filter.right
{
  left:50%;
}
.bg-filter
{
  position:absolute;
  z-index: 1;
  left:0;
  top:0;
  transition:all 0.3s;
  width:50%;
  height:100%;
  background: transparent linear-gradient(88deg, rgba(192, 192, 192, 1) 0%, rgba(187, 187, 194, 1) 0%, rgba(0, 0, 255, 0.9) 100%) 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.16);
  border: 1px solid rgba(0, 0, 0, 0.04);
  border-radius: 7px;
}
.filter.active {
 
font-weight: 600;
color:#fff;
}
.filter {
  cursor: pointer;
  position: relative;
  z-index:2;
  width:50%;
  padding:8px 15px 12px 15px;
  font-size:28px;
}
.element-choice .title {
  width:75%;
  font-size:64px;
  margin-left:auto;
  margin-right:auto;
}
.element-choice .text {
  font-size:32px;
  margin-top:50px;
  width:75%;
  margin-left:auto;
  margin-right:auto;
  margin-bottom:50px;
}
.element-choice .title .black {
  display:inline-block;
  white-space: nowrap;
  background:#000;
  padding:1px 20px 6px 20px;
  color:#fff;
  border-radius: 48px;
}
.element-choice {
  margin-top:150px;
  text-align: center;
}
.see {
  font-size:21px;
  border-bottom: 1px solid black;
  display:inline-block;
  cursor: pointer;
}
.element-top .right {
  background:grey;
  background-image:url('../../public/assets/images/how.jpg');
  background-size:cover;
  width:50%;
  min-height:80vh;
  background-position: center center;
}
.element-top .left .title  {
  font-size:100px;
  word-wrap: normal !important;
  line-height:0.8;
  word-break: keep-all !important;
  font-family: 'ProFontWindows';
}
.element-top .left .title .line1,.element-top .left .title .line2,.element-top .left .title .line3
{
  word-wrap: normal !important;

  word-break: keep-all !important;
}

/* .element-top .left .title .line2
{
  font-size:57px;
  font-weight: 200;
  position:relative;
  left:-30px;
}
.element-top .left .title .line1
{
  position:relative;
  left:50px;
}
.element-top .left .title .line3
{
  position:relative;
  left:70px;
} */
.arrow-bottom {
  display: inline-block;
  clear: both;
}
.element-top .left  {
  width:50%;
  text-align: center;
  display: flex;
  align-items: center;

  padding-right:5%;
  padding-left:5%;
}
.element-top {
  padding-top:100px;
  display: flex;
  padding-left:0%;
  padding-right:0%;
}
.italic {
  font-weight: 600;
  font-style: italic;
}
.subtitle
{
  font-size:33px;
  text-transform: uppercase;
}
</style>